import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App';
import Sweeps from './Sweeps';

import {
	BrowserRouter as Router,
	Route,
	Routes
} from 'react-router-dom';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route exact path="" element={<App />}/>
        <Route exact path="/sweeps" element={<Sweeps />}/>
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
