import './styles/App.css';
import './styles/Menu.css';
import React, { useEffect, useState } from "react";
import Menu from './Menu';

const Sweeps = () => {
    return (
        <div className="App">
            <Menu />
            <div className="container sweeps-container">
                <iframe title="Kokoro ViralSweep" name="ViralSweep Kokoro Embed" 
                    class="vs_main_frame" allowfullscreen="allowfullscreen" id="vs_widget_frame_vs_64138_64138_656305" 
                    src="https://app.viralsweep.com/vrlswp/widget/ea89fc-105956?rndid=105956_592159&amp;framed=1&amp;vs_eid_hash=&amp;"
                    frameborder="0" scrolling="no">
                    Your browser does not support iframe
                </iframe>
            </div>
        </div>
    );
}

export default Sweeps;