import './styles/App.css';
import Swal from "sweetalert2";
import { Slide } from 'react-slideshow-image';

import Menu from './Menu';
import AutoPlaySlideshow from './Slideshow';

import React, { useEffect, useState } from "react";
import { ethers } from "ethers";

import { whitelistAddresses } from './whitelistAddresses';
import KokoroJSON from './utils/KokoroJSON.json';

import teaser from './assets/teaser.gif';
import banner2 from './assets/banner2.png';
import logo from './assets/logo.png';
import discordLogo from './assets/discordlogo.svg';

import shadowWaifu1 from './assets/shadow_waifu_01.gif';
import shadowWaifu2 from './assets/shadow_waifu_02.gif';
import shadowWaifu3 from './assets/shadow_waifu_03.gif';
import shadowWaifu4 from './assets/shadow_waifu_04.gif';
import shadowWaifu5 from './assets/shadow_waifu_05.gif';
import shadowWaifu6 from './assets/shadow_waifu_06.gif';

import backgroundImage from './assets/blood_splat.png';

import team1 from './assets/team_01.png';
import team2 from './assets/team_02.png';
import team3 from './assets/team_03.png';
import team4 from './assets/team_04.png';

import discordLarge from './assets/discord_lrg.png';

import footer from './assets/banner3.png';

import Countdown from 'react-countdown';

const { MerkleTree } = require('merkletreejs');
const keccak256 = require("keccak256");
const { soliditySha256, soliditySha3, soliditySha3Raw } = require("web3-utils");

const App = () => {

  // RINKEBY: 0xD2aB9690d5D6BFf6647660B43d60E6E97407b0Be
  const CONTRACT_ADDRESS = "0xffb4bcc28b1b3936f095f382b6fd9b27cfc9e3f9";

  const [currentAccount, setCurrentAccount] = useState("");
  const [isPreSaleActive, setIsPreSaleActive] = useState("");
  const [isSaleActive, setIsSaleActive] = useState("");
  const [numWaifus, setNumWaifus] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [isOnWl, setIsOnWl] = useState(0);
  const [merkleRoot, setMerkleRoot] = useState(null);
  const [merkleProof, setMerkleProof] = useState(null);
  const [merkleLeaf, setMerkleLeaf] = useState(null);

  const contractABI = KokoroJSON.abi;

  useEffect(() => {
    checkIfWalletIsConnected();
    checkSaleStatuses();
    updateTotalSupply();
    checkIfOnWl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkSaleStatuses = async () => {
    console.log("Check is mint active...");
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, contractABI, signer);

        setIsPreSaleActive(await connectedContract.presaleIsActive());
        setIsSaleActive(await connectedContract.saleIsActive());
        console.log("Pre Sale State: ", await connectedContract.presaleIsActive());
        console.log("Main Sale Stat: ", await connectedContract.saleIsActive());
      }
    } catch (error) {
      console.log(error)
    }
  }

  const checkIfOnWl = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const accounts = await ethereum.request({ method: 'eth_accounts' });

        if (accounts.length !== 0) {
          const myAccount = accounts[0];
          const provider = new ethers.providers.Web3Provider(ethereum);
          const signer = provider.getSigner();
          const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, contractABI, signer);

          var whitelistAddressesParsed = whitelistAddresses.split('\n');
          var whitelistAddressesLowercase = whitelistAddressesParsed.map(address => address.toLowerCase());

          const leafNodes = whitelistAddressesLowercase.map(addr => soliditySha3(addr));
          const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

          const leaf = soliditySha3(myAccount);
          const proof = merkleTree.getHexProof(leaf);
          const root = merkleTree.getRoot();

          console.log("MERKLE ROOT");
          console.log(root.toString('hex'));

          var wlStatus = merkleTree.verify(proof, leaf, root);
          console.log("WHITELIST STATUS FOR " + myAccount + " IS: " +  wlStatus);
          if (wlStatus) {
            setIsOnWl(1);
          } else {
            setIsOnWl(-1);
          }
          setMerkleLeaf(leaf);
          setMerkleProof(proof);
          setMerkleLeaf(leaf);
          return wlStatus;
        }
      }
    }  catch (error) {
      console.log(error)
    }
  }

  const checkIfWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      console.log("Make sure you have metamask!");
      return;
    }

    const accounts = await ethereum.request({ method: 'eth_accounts' });
    window.ethereum.on('accountsChanged', function (accounts) {
      // Time to reload your interface with accounts[0]!
      setCurrentAccount(accounts[0]);
      console.log("CHANGED ACCT APP");
      checkIfOnWl();
    });
    if (accounts.length !== 0) {
      const account = accounts[0];
      setCurrentAccount(account)
      checkIfOnWl();
      console.log("have an account");
    } else {
      console.log("no account");
    }

  }

  const connectWallet = async () => {
    try {
      const { ethereum } = window;
      if (!ethereum) {
        console.log("no metamask detected");
        Swal.fire(`Please install Metamask!`, '', 'info');
        setNumWaifus(0);
      } else {
        const accounts = await ethereum.request({ method: "eth_requestAccounts"});

        if (accounts.length !== 0) {
          const account = accounts[0];
          console.log("have account", account);
          setCurrentAccount(account);
          await checkSaleStatuses();
          await updateTotalSupply();
          await checkIfOnWl();
          window.location.reload(false);
        } else {
          console.log("no accounts!");
        }
      }
    } catch (error) {
      console.log("error", error);
      Swal.fire(`Please install Metamask!`, '', 'info');
    }
  }

  const updateTotalSupply = async () => {
    try {
      // TODO: CHANGE TO MAINNET
      const provider = new ethers.providers.getDefaultProvider('mainnet');
      const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, contractABI, provider);

      let supply = await connectedContract.totalSupply();
      console.log("supply");
      console.log(`${supply}`);
      setTotalSupply(parseInt(`${supply}`));
    } catch (error) {
      console.log("cant find contract?");
    }
  }

  const whitelistMint = async () => {

    try {
      const { ethereum } = window;

      if (ethereum) {
        if ((isOnWl == 1) && merkleProof !== null) {
          const provider = new ethers.providers.Web3Provider(ethereum);
          const signer = provider.getSigner();
          const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, contractABI, signer);

          try {
            if (numWaifus > 0) {
              console.log("Going to pop wallet now to pay gas...")

              var supply = parseInt(String(await connectedContract.totalSupply()));

              console.log("supply");
              console.log(parseInt(supply));
              var amount = numWaifus * 0.065;

              if (amount > 3) {
                Swal.fire(`Can mint max 3 Waifus during whitelist sale!`, '', 'info');
              }

              console.log("amount");
              console.log(amount);

              var options = {
                value: ethers.utils.parseEther(String(amount)),
              };

              var methodGasEstimate = await connectedContract.estimateGas.whitelistMint(numWaifus, merkleProof, options);
              methodGasEstimate = parseInt(methodGasEstimate);
              console.log(`contract method gas estimate ${methodGasEstimate}`);
              console.log(`amount ${amount}`);

              options = {
                value: ethers.utils.parseEther(String(amount)),
                gasLimit: parseInt(methodGasEstimate * 1.2),
              }

              let nftTxn = await connectedContract.whitelistMint(numWaifus, merkleProof, options);
              console.log("Mining mint... please wait.")
              await nftTxn.wait();
              Swal.fire(`Successfully minted ${numWaifus} Kokoro students!`, '', 'info');
              console.log(`Mined, see transaction: https://etherscan.io/tx/${nftTxn.hash}`);
            }
          } catch (error) {
            var firstIndex = error.message.lastIndexOf('"message":') + '"message":'.length;
            var lastIndex = error.message.lastIndexOf(' method=');
            console.log("first index", firstIndex);
            console.log("last index", lastIndex);
            var errorMessage = error.message.substring(
              firstIndex,
              lastIndex
            ).replaceAll("}", "").replaceAll("{", "").replaceAll(",", "").replaceAll("=", "").replaceAll('"', "");
            if (errorMessage !== "") {
              Swal.fire(errorMessage, '', 'info');
              console.log(error);
              console.log(error.message);
            }
          }
        } else {
          Swal.fire(`You are not whitelisted!`, '', 'info');
        }

      } else {
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error) {
      console.log(error)
    }

  }

  const mint = async () => {

    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, contractABI, signer);

        try {
          if (numWaifus > 0) {
            console.log("Going to pop wallet now to pay gas...")

            var supply = parseInt(String(await connectedContract.totalSupply()));

            console.log("supply");
            console.log(parseInt(supply));
            var amount = numWaifus * 0.065;

            console.log("amount");
            console.log(amount);

            var options = {
              value: ethers.utils.parseEther(String(amount)),
            };

            var methodGasEstimate = await connectedContract.estimateGas.mint(numWaifus, options);
            methodGasEstimate = parseInt(methodGasEstimate);
            console.log(`contract method gas estimate ${methodGasEstimate}`);
            console.log(`amount ${amount}`);

            options = {
              value: ethers.utils.parseEther(String(amount)),
              gasLimit: parseInt(methodGasEstimate * 1.2),
            }

            let nftTxn = await connectedContract.mint(numWaifus, options);
            console.log("Mining mint... please wait.")
            await nftTxn.wait();
            Swal.fire(`Successfully minted ${numWaifus} Kokoro students!`, '', 'info');
            console.log(`Mined, see transaction: https://etherscan.io/tx/${nftTxn.hash}`);
          }
        } catch (error) {
          var firstIndex = error.message.lastIndexOf('"message":') + '"message":'.length;
          var lastIndex = error.message.lastIndexOf(' method=');
          console.log("first index", firstIndex);
          console.log("last index", lastIndex);
          var errorMessage = error.message.substring(
            firstIndex,
            lastIndex
          ).replaceAll("}", "").replaceAll("{", "").replaceAll(",", "").replaceAll("=", "").replaceAll('"', "");
          if (errorMessage !== "") {
            Swal.fire(errorMessage, '', 'info');
            console.log(error);
            console.log(error.message);
          }
        }

      } else {
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error) {
      console.log(error)
    }

  }

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <div>
          <p className='title-text'>BUY ON OPENSEA</p>
        </div>
      );
    } else {
      // Render a countdown
      return (
        <div>
          {/* <p className='title-text'>{totalSupply} / 8,888 MINTED</p> */}
          <p className="countdown-large">{days} D | {hours} H | {minutes} M | {seconds} S</p>
        </div>
      );
    }
  };

  return (
    <div className="App">
      <Menu />
      <div className="container mint-container">
        <div className="banner" style={{ backgroundImage: `url(${banner2})`}}>
          <div className="banner-logo">{/* <img className="banner-logo-img" src={logo} />   */}</div>
        </div>  
        <div className="logo">
          <img className="logo-img" src={logo} />
        </div>
        <p className='title-text'>2,222 waifus fighting for your love</p>
        <img className="teaser" src={teaser} />
          <br id="mint" />
          <br />
          <p className="countdown">FEB 15 | 12PM PST</p>
          <p className="countdown">0.065 WL & PUBLIC</p>
          {currentAccount === "" ? (
            <button onClick={connectWallet} className="cta-button connect-wallet-button">
              Connect
            </button>
          ) : [
            (!isSaleActive && !isPreSaleActive ? (
              <div className="button-container">
                <button className="cta-button connect-wallet-button">
                  SOLD OUT
                </button>
              </div>
                ) : null
            ),
            (!isSaleActive && isPreSaleActive ? (
                <div className="mint-specific">
                  <button onClick={whitelistMint} className="cta-button connect-wallet-button">
                    WHITELIST MINT
                  </button>
                  <div className="mint-quantity">
                    <textarea 
                      name = "numWaifus"
                      type="text"
                      id="numWaifus"
                      className="textarea-entry"
                      placeholder="MAX 3"
                      onChange={e => setNumWaifus(e.target.value)}
                    >
                    </textarea>
                    </div>
                </div>
                ) : null
            ),
            (isSaleActive ? (
              <div className="mint-specific">
                <button onClick={mint} className="cta-button connect-wallet-button">
                  PUBLIC MINT
                </button>
                <div className="mint-quantity">
                  <textarea 
                    name = "numWaifus"
                    type="text"
                    id="numWaifus"
                    className="textarea-entry"
                    placeholder="MAX 5"
                    onChange={e => setNumWaifus(e.target.value)}
                  >
                  </textarea>
                  </div>
              </div>
              ) : null
          ),
          ]}
          <div className="countdown">
            <Countdown date="2022-02-15T12:00:00-08:00" renderer={renderer}>
            </Countdown>
          </div>
          <div className="smol-container">
            <br />
          </div>
      </div>
      <div id="art" className="container art-container">
        <div className="carousel">

          {/* <div className="horizontal-divider"></div> */}
          <div className="art-preview-container">
            <AutoPlaySlideshow />
          </div>
          <div className="art-text-container">
              <p className="art-text-large">YOUR SENPAI HAS NOTICED YOU</p>
              <br />
              <p className="art-text-small">
                You are the first male student who has attended Kokoro Academy for Troubled Youth. Soon after you 
                arrive, you become the lustful eye of the most psychotic girls in school- who would literally kill to
                be with you!
              </p>
              <br />
              <p className="art-text-small">
                Choose your waifu and protect her from the other psychotic girls who wish to do her harm!
              </p>
              <br />
              <p className="art-text-small">
                Each waifu is a unique card which will grant you access to game features and other content.
              </p>
          </div>
        </div>
        <div className="hidden-previews">
          <div className="hidden-preview-row">
            <img className="hidden-preview-img" src={shadowWaifu1} />
            <img className="hidden-preview-img" src={shadowWaifu2} />
            <img className="hidden-preview-img hidden-preview-img-last" src={shadowWaifu3} />
          </div>
          <div className="hidden-preview-row"> 
            <img className="hidden-preview-img" src={shadowWaifu4} />
            <img className="hidden-preview-img" src={shadowWaifu5} />
            <img className="hidden-preview-img hidden-preview-img-last" src={shadowWaifu6} />
          </div>
        </div>
      </div>
      <div id="vsscript_105956_592159"></div><script async type="text/javascript" src="https://app.viralsweep.com/vsa-lightbox-ea89fc-105956.js?sid=105956_592159"></script>
      <div id="utility" className="container roadmap-container" style={{ backgroundImage: `url(${backgroundImage})`}}>
        <div className="roadmap-text-container">
          <p className="roadmap-text-large">ROADMAP</p>
          <div className="roadmap-divider roadmap-divider-first"></div>
          <p className="roadmap-text-small"><b>1st Day of School</b></p>
          <p className="roadmap-text-small">Kokoro Academy opens its doors for the new semester. The story begins! An interactive, growing timeline where community engagement will decide the fate of the students at the academy.</p>
          <div className="roadmap-divider"></div>
          <p className="roadmap-text-small"><b>Weapons!</b></p>
          <p className="roadmap-text-small">A batch of new items raffled to collectors to weaponize their student NFTs. These items can be traded and used to make existing students more lethal, and will affect your NFT's unique story timeline.</p>
          <div className="roadmap-divider"></div>
          <p className="roadmap-text-small"><b>Clubs</b></p>
          <p className="roadmap-text-small">Holders will be given special access passes to one of 8 student clubs and will receive unique giveaways depending on your choice! 8 House President NFTs will be awarded to leaders to bring their club to victory.</p>
          <div className="roadmap-divider"></div>
          <p className="roadmap-text-small"><b>Teacher's Pet</b></p>
          <p className="roadmap-text-small">A drop of 20 legendary new students NFTs that feature new traits not found in the rest of the collection for existing collectors. These characters will give players access to the <b>writer's corner</b> where they will be involved more closely with the Kokoro team on overall story components.</p>
          <div className="roadmap-divider"></div>
          <p className="roadmap-text-small"><b>The Story Continues</b></p>
          <p className="roadmap-text-small">Battle Royale & RPG-style minigames, manga, soundtrack, and timed events available for holders shortly after minting.</p>

        </div>
        <div className="class-session-container">
          <p className="team-title">CLASS IS IN SESSION</p>
          <a href="https://discord.gg/EbXHxYSFNS">
            <img className="discord-large" src={discordLarge} />
          </a>
          <img />
        </div>
      </div>

      <div id="team" className="container team-container">
        <p className="team-title">TEAM</p>
        <div className="team-bios">
          <div className="team-bio">
            <div className="team-bio-left">
              <img className="team-bio-img" src={team1}/>
              <p className="team-bio-text">RealYandereDev</p>
              <p className="team-bio-text-small">CREATOR</p>
            </div>
            <div className="team-bio-right team-description-text-right">
              Co-Founder of S8 Tattoo and Kiage Team, RealYandere has worked on numerous design projects in artistic
              mediums throughout East Asia and the United States.
              He is skilled in understanding the cultural significance of art and design trends.
            </div>
          </div>
          <div className="team-bio">
            <div className="team-bio-left">
              <img className="team-bio-img" src={team2}/>
              <p className="team-bio-text">NaisuWarudo</p>
              <p className="team-bio-text-small">ARTIST</p>
            </div>
            <div className="team-bio-right team-description-text-right">
              Has a varied background as former Artistic Director of Cartoon Network and Adult Swim.
              A master of left and right brain thought, 
              NaisuWarudo sees the crypto space as the ultimate convergence of art and finance
            </div>
          </div>
          <div className="team-bio">
            <div className="team-bio-left">
              <img className="team-bio-img" src={team3}/>
              <p className="team-bio-text">AK1RA</p>
              <p className="team-bio-text-small">ARTIST</p>
            </div>
            <div className="team-bio-right team-description-text-right">
            Co-Founder of S8 Tattoo and Kiage Team, AK1RA has the valuable asset of being
             able to read the internet's hivemind and predict digital cultural trends with pinpoint accuracy.
            </div>
          </div>
        </div>
      </div>
      <a href="/sweeps">
        <div className="whitelist-comp">
          <p><u>[WHITELIST COMPETITION]</u></p>
        </div>
      </a>
      <div id="footer" className="container footer-container">
      <div className="banner footer" style={{ backgroundImage: `url(${footer})`}}>
          <div className="banner-logo">{/* <img className="banner-logo-img" src={logo} />   */}</div>
        </div>  
      </div>
    </div>
  );
};

export default App;  