import React, { useState } from "react";
import { Slide } from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css'

import './styles/Slideshow.css';

import waifu1 from './assets/preview_waifu_01.jpg';
import waifu2 from './assets/preview_waifu_02.jpg';
import waifu3 from './assets/preview_waifu_03.jpg';
import waifu4 from './assets/preview_waifu_04.jpg';
import waifu5 from './assets/preview_waifu_05.jpg';

const slideImages = [
  'images/slide_2.jpg',
  'images/slide_3.jpg',
  'images/slide_4.jpg'
];

const Slideshow = () => {
    return (
      <div className="slide-container">
        <Slide easing="ease" duration="3000">
          <div className="each-slide">
            <div className="slide-img" style={{'backgroundImage': `url(${waifu1})`}}>
            </div>
          </div>
          <div className="each-slide">
            <div className="slide-img" style={{'backgroundImage': `url(${waifu2})`}}>
            </div>
          </div>
          <div className="each-slide">
            <div className="slide-img" style={{'backgroundImage': `url(${waifu3})`}}>
            </div>
          </div>
          <div className="each-slide">
            <div className="slide-img" style={{'backgroundImage': `url(${waifu4})`}}>
            </div>
          </div>
          <div className="each-slide">
            <div className="slide-img" style={{'backgroundImage': `url(${waifu5})`}}>
            </div>
          </div>
        </Slide>
      </div>
    )
};

export default Slideshow;

// const AutoPlaySlideshow = () => {
//   const [autoplay, setAutoplay] = useState(true);

//   const style = {
//     textAlign: "center",
//     background: "teal",
//     padding: "200px 0",
//     fontSize: "30px",
//     height: "100%"
//   }

//   return (
//     <div>
//       <div>
//         <Slide autoplay={autoplay}>
//           <div style={style}>
//             <img className="slide-img" src={waifu1} />
//           </div>
//           <div style={style}>
//             <img className="slide-img" src={waifu2} />
//           </div>
//           <div style={style}>
//             <img className="slide-img" src={waifu3} />
//           </div>
//           <div style={style}>
//             <img className="slide-img" src={waifu4} />
//           </div>
//           <div style={style}>
//             <img className="slide-img" src={waifu5} />
//           </div>
//         </Slide>
//       </div>

//       <div className="autoplay-buttons">
//         Autplay is {autoplay ? 'on' : 'off'}
//       </div>
//       <div className="autoplay-buttons">
//         <button type="button" onClick={() => setAutoplay(false)}>Pause</button>
//         <button type="button" onClick={() => setAutoplay(true)}>Play</button>
//       </div>
//     </div>
//   );
// };

// export default AutoPlaySlideshow;